import React from "react"
import PropTypes from "prop-types"
import { getImage } from "gatsby-plugin-image"

import { Wrapper, WrapperSizes } from "components"
import { HeadlineSizes, ParagraphSizes, BUTTON_STYLE_TYPE } from "ui"
import S from "./HomeHeader.styled"

const HeaderHome = ({ title, description, buttonName, imageFluid, _ref, lang }) => ( //eslint-disable-line
  <S.Header ref={_ref}>
    <S.ContentWrapper id="HomeHeader_Content">
      <Wrapper size={WrapperSizes.LARGE} pushWithPadding>
        <S.Content>
          <S.Title tag="h1" sizeType={HeadlineSizes.XXL}>
            {title}
          </S.Title>
          <S.Intro sizeType={ParagraphSizes.XL}>{description}</S.Intro>
          <S.StyledButton
            styleType={BUTTON_STYLE_TYPE.LIGHT}
            link={lang === "EN" ? "/en/project" : "/proyecto"}
          >
            {buttonName}
          </S.StyledButton>
        </S.Content>
      </Wrapper>
    </S.ContentWrapper>
    <S.ImageWrapper id="HomeHeader_Image">
      <S.Hero image={getImage(imageFluid)} alt="" />
    </S.ImageWrapper>
  </S.Header>
)

HeaderHome.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonName: PropTypes.string,
  imageFluid: PropTypes.shape({
    aspectRatio: PropTypes.number,
    base64: PropTypes.string,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
  }),
}

HeaderHome.defaultProps = {
  title: "",
  description: "",
  buttonName: "",
  imageFluid: null,
}

export default HeaderHome
