import React, { useRef, useEffect, useState, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import PropTypes from "prop-types"
import { gsap } from "gsap"

import { State } from "layouts"
import { HeadlineSizes, Loader } from "ui"
import { IconsSection, ImageSection, WrapperSizes, SEO, Footer } from "components" // eslint-disable-line

import HomeHeader from "./HomeHeader"
import HomeNews from "./HomeNews"

const query = graphql`
  {
    allFile(filter: { name: { regex: "/home-icon-no/" } }) {
      nodes {
        publicURL
        name
      }
    }
    allWpPage(filter: { home: { homeLang: { regex: "/ES|EN/" } } }) {
      edges {
        node {
          title
          home {
            homeLang
            homeHero {
              title
              description
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
              buttonName
            }
            homeSection1 {
              title
              description
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000)
                  }
                }
              }
            }
            homeIcons {
              iconNo1 {
                description
              }
              iconNo2 {
                description
              }
              iconNo3 {
                description
              }
              iconNo4 {
                description
              }
              iconNo5 {
                description
              }
            }
            homeSection2 {
              title
              buttonName
            }
          }
        }
      }
    }
    allWpPost(filter: { post: { language: { regex: "/ES|EN/" } } }) {
      edges {
        node {
          title
          id
          date
          content
          categories {
            nodes {
              name
              id
            }
          }
          post {
            language
            postCover {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }
        }
      }
    }
  }
`

const HomeTemplate = ({ lang }) => {
  const { allFile: { nodes: icons }, allWpPage: { edges: languageDataVersions }, allWpPost: { edges: languagePostVersions } } = useStaticQuery(query) // eslint-disable-line
  const [pageContent, setPageContent] = useState(null)
  const [loading, setLoading] = useState(true)

  const { state, setIntroAnimation } = useContext(State)

  useEffect(() => {
    const { node: page } = languageDataVersions.find(
      ({ node: { home } }) => home.homeLang === lang
    )

    const posts = languagePostVersions.filter(
      ({ node }) => node?.post?.language === lang
    ).sort((a,b)=> {
      return  new Date(b.node.date) - new Date(a.node.date)
    })

    const onlyTwoPosts = posts.filter((_, index) => index < 2)

    setPageContent({
      home: page?.home,
      title: page?.title,
      posts: onlyTwoPosts,
    })

    const id = setTimeout(() => setLoading(false), 500)
    return () => clearTimeout(id)
  }, [languageDataVersions])

  const homeHeaderRef = useRef(null)
  const firstSectionRef = useRef(null)
  const secondSectionRef = useRef(null)
  const homeNewsRef = useRef(null)
  const homeRef = useRef(null)

  useEffect(() => {
    if (loading) return

    const homeHeader = homeHeaderRef.current
    const homeHeaderImage = homeHeader.querySelector("#HomeHeader_Image")
    const homeHeaderContent = homeHeader.querySelector("#HomeHeader_Content")

    const home = homeRef.current
    const firstSection = firstSectionRef.current
    const secondSection = secondSectionRef.current
    const newsSection = homeNewsRef.current

    if (state.introAnimationOff) {
      const tl = gsap.timeline()

      tl.fromTo(home, { opacity: 0 }, { opacity: 1, duration: 0.25 })
        .fromTo(
          homeHeaderContent,
          { x: "-=1000", opacity: 0 },
          { x: "+=1000", opacity: 1, duration: 0.25 }
        )
        .fromTo(
          homeHeaderImage,
          { x: "+=1000", opacity: 0 },
          { x: "-=1000", opacity: 1, duration: 0.25 }
        )
    } else {
      const tl = gsap.timeline()

      tl.fromTo(home, { opacity: 0 }, { opacity: 1, duration: 0.6 })
        .fromTo(
          homeHeaderContent,
          { x: "-=1000", opacity: 0 },
          { x: "+=1000", opacity: 1, duration: 0.6 }
        )
        .fromTo(
          homeHeaderImage,
          { x: "+=1000", opacity: 0 },
          { x: "-=1000", opacity: 1, duration: 0.6 }
        )
    }

    gsap.fromTo(
      firstSection,
      { y: "+=50", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        scrollTrigger: {
          trigger: homeHeader,
          start: "bottom 50%",
        },
      }
    )

    gsap.fromTo(
      secondSection,
      { y: "+=50", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        scrollTrigger: {
          trigger: firstSection,
          start: "bottom 50%",
        },
      }
    )

    gsap.fromTo(
      newsSection,
      { y: "+=50", opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        scrollTrigger: {
          trigger: secondSection,
          start: "80% 50%",
        },
      }
    )

    setIntroAnimation(true)
  }, [homeHeaderRef, firstSectionRef, secondSectionRef, homeNewsRef, loading])

  return (
    <div ref={homeRef}>
      <SEO title={pageContent?.title || ""} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <HomeHeader
            _ref={homeHeaderRef}
            title={pageContent?.home?.homeHero?.title}
            description={pageContent?.home?.homeHero?.description}
            buttonName={pageContent?.home?.homeHero?.buttonName}
            imageFluid={
              pageContent?.home?.homeHero?.image?.localFile
            }
            lang={lang}
          />
          <ImageSection
            _ref={firstSectionRef}
            reverse
            headline={{
              sizeType: HeadlineSizes.XL,
              tag: "h2",
              value: pageContent?.home?.homeSection1?.title,
            }}
            paragraphs={parse(
              pageContent?.home?.homeSection1?.description || "",
              {
                trim: true,
              }
            )}
            images={[
              {
                fluid: pageContent?.home?.homeSection1?.image?.localFile,
                margin: "-10% 0 0",
              },
            ]}
          />
          <IconsSection
            _ref={secondSectionRef}
            width="20%"
            wrapperSize={WrapperSizes.MEDIUM}
            icons={[
              {
                url: icons.filter(icon => icon.name === "home-icon-no-1")[0]
                  .publicURL,
                paragraph: parse(
                  pageContent?.home?.homeIcons?.iconNo1?.description || "",
                  { trim: true }
                ),
              },
              {
                url: icons.filter(icon => icon.name === "home-icon-no-2")[0]
                  .publicURL,
                paragraph: parse(
                  pageContent?.home?.homeIcons?.iconNo2?.description || "",
                  { trim: true }
                ),
              },
              {
                url: icons.filter(icon => icon.name === "home-icon-no-3")[0]
                  .publicURL,
                paragraph: parse(
                  pageContent?.home?.homeIcons?.iconNo3?.description || "",
                  { trim: true }
                ),
              },
              {
                url: icons.filter(icon => icon.name === "home-icon-no-4")[0]
                  .publicURL,
                paragraph: parse(
                  pageContent?.home?.homeIcons?.iconNo4?.description || "",
                  { trim: true }
                ),
              },
              {
                url: icons.filter(icon => icon.name === "home-icon-no-5")[0]
                  .publicURL,
                paragraph: parse(
                  pageContent?.home?.homeIcons?.iconNo5?.description || "",
                  { trim: true }
                ),
              },
            ]}
          />
          {!!pageContent?.posts?.length && (
            <HomeNews
              _ref={homeNewsRef}
              title={pageContent?.home?.homeSection2?.title}
              posts={pageContent?.posts}
              buttonName={pageContent?.home?.homeSection2?.buttonName}
            />
          )}
          <Footer />
        </>
      )}
    </div>
  )
}

HomeTemplate.propTypes = {
  lang: PropTypes.string.isRequired,
}

export default HomeTemplate
