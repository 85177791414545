import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Headline, Paragraph, Button } from "ui"
import { devices } from "theme/devices"

const Header = styled.header`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 2rem;

  ${devices.tabletL} {
    flex-direction: row;
    align-items: center;
    min-height: 40vh;
    margin-bottom: 25vh;
    background: ${({ theme }) => theme.neutral60};

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 33.3333%;
      top: -10rem;
      display: block;
      width: 50%;
      height: 105vh;
      background: ${({ theme }) => theme.primary20};
    }
  }
`

const Title = styled(Headline)`
  color: ${({ theme }) => theme.primary20};
  margin: 0 0 1rem 0;

  ${devices.mobile} {
    margin-bottom: 1.5rem;
  }

  ${devices.tabletL} {
    margin-bottom: 1rem;
  }
`

const Intro = styled(Paragraph)`
  display: block;
  color: ${({ theme }) => theme.primary20};

  &:last-of-type {
    ${devices.mobile} {
      margin: 0 0 2rem 0;
    }

    ${devices.mobileL} {
      width: 80%;
    }

    ${devices.tablet} {
      margin-top: 3rem;
    }

    ${devices.tabletL} {
      margin-top: 0.5rem;
      line-height: 1.5;
    }

    ${devices.desktopHD} {
      margin-top: 2rem;
      margin-bottom: 4rem;
      width: 50%;
    }
  }
`

const ContentWrapper = styled.div`
  width: 80%;
  padding: 3rem 0;
  margin-top: -0.1rem;
  background: ${({ theme }) => theme.neutral60};
  opacity: 0;

  ${devices.tabletL} {
    width: 100%;
    background: none;
  }
`

const Content = styled.div`
  ${devices.tabletL} {
    width: 45%;
  }

  ${devices.tabletL} {
    margin-left: 4rem;
  }

  ${devices.desktopHD} {
    margin-left: 6rem;
  }

  @media (min-width: 1800px) {
    margin-left: 0;
  }
`

const ImageWrapper = styled.div`
  overflow: hidden;
  padding-left: 2rem;
  background: ${({ theme }) => theme.neutral60};
  border-bottom-right-radius: 6rem;
  opacity: 0;

  @media (orientation: landscape) {
    height: 50vh;
  }

  ${devices.mobile} {
    padding-left: 3rem;
  }

  ${devices.mobileL} {
    padding-left: 6rem;
  }

  ${devices.tablet} {
    padding-left: 8rem;
  }

  ${devices.tabletL} {
    padding-left: 0;
    position: absolute;
    top: 10vh;
    right: 0;
    border-top-left-radius: 12rem;
    border-bottom-right-radius: 0;
    width: 50%;
    height: 100%;
  }
`

const Hero = styled(GatsbyImage)`
  display: block;
  width: 100%;
  object-fit: cover;

  @media (orientation: landscape) {
    height: 50vh;
  }

  ${devices.tablet} {
    min-height: 60vh;
  }

  ${devices.tabletL} {
    height: 100%;
  }
`

const StyledButton = styled(Button)`
  margin-top: 3rem;
`

export default {
  Header,
  StyledButton,
  ContentWrapper,
  Title,
  Intro,
  Hero,
  ImageWrapper,
  Content,
}
