import styled from "styled-components"
import { devices } from "theme/devices"
import { Headline } from "ui"

const StyledHeadline = styled(Headline)`
  text-align: center;

  ${devices.desktop} {
    margin-top: 8rem;
  }
`

const NewsWrapper = styled.div`
  ${devices.mobile} {
    margin-top: 2.4rem;
  }

  ${devices.mobileL} {
    margin-top: 3rem;
  }

  ${devices.tabletL} {
    display: flex;
    align-items: flex-start;
    margin-top: 3.6rem;
  }

  ${devices.desktop} {
    margin: 4rem 0 8rem;
  }
`

export default { StyledHeadline, NewsWrapper }
