import React from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import { Wrapper, WrapperSizes, News } from "components"
import { HeadlineSizes } from "ui"
import S from "./HomeNews.styled"

const HomeNews = ({ title, posts, _ref }) => (
  <Wrapper size={WrapperSizes.MEDIUM} _ref={_ref}>
    <S.StyledHeadline sizeType={HeadlineSizes.L} tag="h2">
      {title}
    </S.StyledHeadline>
    <S.NewsWrapper>
      {posts?.map(post => (
        <News
          key={post?.node?.id}
          date={`${post?.node?.categories?.nodes[0]?.name} | ${new Date(
            post?.node?.date
          ).toLocaleDateString()}`}
          title={post?.node?.title}
          lead={parse(post?.node?.content, { trim: true })}
          imageFluid={
            post?.node?.post?.postCover?.localFile
          }
        />
      ))}
    </S.NewsWrapper>
  </Wrapper>
)

HomeNews.propTypes = {
  title: PropTypes.string,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      id: PropTypes.string,
      content: PropTypes.string,
      imageFluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
        sizes: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
      }),
      label: PropTypes.string,
    })
  ),
}

HomeNews.defaultProps = {
  title: "",
  posts: [],
}

export default HomeNews
